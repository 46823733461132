.todo-list {
 display: flex;
 justify-content: center;
 align-items: center;
 position: fixed;
 left: 40%;
}

.icon {
    margin-left: 12px;
    margin-right: 12px;
    width: 30px;
}
.icon-container {
    position: fixed;
    right: 40%;
}

